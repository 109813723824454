import React, { Component } from 'react';

class SelectBox extends Component {
	render() {
		const {options} = this.props;
		const optionTags = options ? renderOptions(options) : [];
		const lowercaseName = this.props.fieldName.toLowerCase();
		
		return (
			<div key={this.props.fieldName} className={`${lowercaseName}-field widget-field`}>
				<label htmlFor={`${lowercaseName}-select`} className="search-label font-size-p4">{this.props.label}</label>
				<select id={`${lowercaseName}-select`} className="search-select font-size-p4" onChange={(event) => {this.props.handleChange(event, this.props.selectedPropertyKey)}} value={this.props.value} data-test-id={lowercaseName} data-track="search-filter" data-track-id={lowercaseName}>
					{optionTags}
				</select>
				{/* Removing tooltip - I have kept the code related to App.js intact so we don't miss the logic if we want to show it again */}
				{/* {tooltipText && <div className='tooltiptext'>{tooltipText}</div>} */}
			</div>
		);
	}
}

function renderOptions(options) {
	return options.map((option) => {
		return (
			<option key={option.label} value={option.value}>{option.label}</option>
		);
	});
}

export default SelectBox;