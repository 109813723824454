export default class Config {
	constructor() {
		const DEPLOY_TARGET = process.env.REACT_APP_DEPLOY_TARGET;
		this.apiHost = 'https://gw.qa-api.princess.com/pcl-web/stage';
		this.wwwHost = 'https://devwww.princess.com';
		this.bookHost = 'https://gw.qa-api.princess.com/pcl-web/stage';
		this.productCompany = 'PC';
		this.agencyId = 'DIRPB';
		this.ube = 'PCPB';
		this.defaultCountry = 'US';
		this.pclClientId = 'a777f4065b5df90d91400b6ff006051e';
		if (DEPLOY_TARGET === 'stage') {
			this.wwwHost = 'https://stagewww.princess.com';			
		} else if (DEPLOY_TARGET === 'uat') {
			this.wwwHost = 'https://uatwww.princess.com';
		} else if (DEPLOY_TARGET === 'preprod') {
			this.wwwHost = 'https://preprodwww.princess.com';
		} else if (DEPLOY_TARGET === 'prod') {
			this.ube = 'PCDIR';
			this.pclClientId = '32e7224ac6cc41302f673c5f5d27b4ba';
			this.apiHost = 'https://gw.api.princess.com/pcl-web/internal';
			this.wwwHost = 'https://www.princess.com';
			this.bookHost = 'https://gw.api.princess.com/pcl-web/internal';
		}
	}
}