import { format as formatDate, parse as parseDate, getUnixTime } from 'date-fns';
import { 
	durationLimits,
	sortByLabelAlpha,
	sortBySortValue,
	durationToQueryParam
 } from './Utils';

export default class SearchFilters {
	constructor(config, store) {
		this.config = config;
		this.store = store;
	}

	filterBy(collection, propertyName, value) {
		if (!value) {
			return collection;
		}
		return collection.filter((item) => {
			const property = item[propertyName];
			return Array.isArray(property) ? 
				property.includes(value) : 
				property === value;
		})
	}

	filter(selected) {
		let { selectedTrade, selectedDate, selectedPort, selectedDuration } = selected;
		let store = this.store;
		store.productsByTrade = this.productsByTrade(selectedTrade);
		store.productsByDate = this.productsByDate(selectedDate);
		store.productsByPort = this.productsByPort(selectedPort);
		store.productsByDuration = this.productsByDuration(selectedDuration);
		store.matchesTrades = this.matchesTrades();
		store.matchesDates = this.matchesDates();
		store.matchesPorts = this.matchesPorts();
		store.matchesDuration = this.matchesDuration();
		store.tradesEnabled = this.tradesEnabled();
		store.datesEnabled = this.datesEnabled();
		store.portsEnabled = this.portsEnabled();
		store.durationsEnabled = this.durationsEnabled();
	}

	productsByTrade(selectedTrade) {
		let products = this.store.products;
		return this.filterBy(products, 'trade', selectedTrade);
	}

	productsByDate(selectedDate) {
		let products = this.store.products;
		return this.filterBy(products, 'dates', selectedDate);
	}

	productsByPort(selectedPort) {
		let products = this.store.products;
		return this.filterBy(products, 'ports', selectedPort);
	}

	productsByDuration(selectedDuration) {
		let products = this.store.products;
		return this.filterBy(products, 'durations', selectedDuration);
	}

	matchesTrades() {
		let { productsByDate, productsByPort, productsByDuration } = this.store;
		return this.intersect(productsByDate, productsByPort, productsByDuration);
	}

	matchesDates() {
		let { productsByTrade, productsByPort, productsByDuration } = this.store;
		return this.intersect(productsByTrade, productsByPort, productsByDuration);
	}

	matchesPorts() {
		let { productsByTrade, productsByDate, productsByDuration } = this.store;
		return this.intersect(productsByTrade, productsByDate, productsByDuration);
	}

	matchesDuration() {
		let { productsByTrade, productsByDate, productsByPort } = this.store;
		return this.intersect(productsByTrade, productsByDate, productsByPort);
	}

	tradesEnabled() {
		let { matchesTrades } = this.store;
		let tradesEnabled = matchesTrades.reduce((tradesEnabled, product) => {
			let isValid = this.validateTrade(product.trade);
			if (isValid && !tradesEnabled.includes(product.trade)) {
				tradesEnabled.push(product.trade);
			}
			return tradesEnabled;
		}, []);
		return tradesEnabled;
	}

	datesEnabled() {
		let { matchesDates } = this.store;
		let datesEnabled = matchesDates.reduce((dates, product) => {
			return dates.concat(product.dates);
		}, []).uniqify();
		return datesEnabled;
	}

	portsEnabled() {
		let { matchesPorts } = this.store;
		let portsEnabled = matchesPorts.reduce((ports, product) => {
			return ports.concat(product.ports);
		}, []).uniqify();
		return portsEnabled;
	}

	durationsEnabled() {
		let { matchesDuration } = this.store;
		let durationsEnabled = matchesDuration.reduce((durations, product) => {
			return durations.concat(product.durations);
		}, []).uniqify();
		return durationsEnabled;
	}

	validateTrade(tradeId) {
		return !!tradeId && this.store.trades.some((trade) => {
			return trade.id === tradeId;
		});
	}

	intersect(...productArrays) {
		let allProducts = this.store.products;

		// Define new array where none of the arrays are equal in length to the total length of all voyages.
		let arraysN = productArrays.filter((a) => {
			return a.length !== allProducts.length;
		});

		// if we have filtered out every array, we just return the original voyage array
		if (arraysN.length === 0) {
			return allProducts;
		}

		// if we have only 1 array there is no need to intersect, just return that array.  A âˆ© U = A
		if (arraysN.length === 1) {
			return  arraysN[0];
		}
		let results = arraysN.pop().filter((candidate) => {
			for (let i = 0; i < arraysN.length; i++) {
				let found = false;
				let array = arraysN[i];
				for (let j = 0; j < array.length; j++) {
					if (array[j] === candidate) {
						found = true;
						break;
					}
				}
				if (found === false) {
					return false;
				}
			}
			return true;
		});

		return results;
	}

	buildFields(state) {
		const { selectedTrade, selectedDate, selectedPort } = state;
		const { tradesEnabled, datesEnabled, portsEnabled } = this.store;
		const tradeOptions = this.buildTradeOptions(tradesEnabled, selectedTrade);
		const dateOptions = this.buildDateOptions(datesEnabled, selectedDate);
		const portOptions = this.buildPortOptions(portsEnabled, selectedPort);

		const tradeField = {
			name: 'Destination',
			selectedPropertyKey: 'selectedTrade',
			options: tradeOptions
		};

		const dateField = {
			name: 'Date',
			selectedPropertyKey: 'selectedDate',
			options: dateOptions
		};

		const portField = {
			name: 'Port', 
			selectedPropertyKey: 'selectedPort',
			options: portOptions
		};

		// const durationField = {
		// 	name: 'Duration',
		// 	selectedPropertyKey: 'selectedDuration',
		// 	options: durationOptions
		// };
		return [tradeField, portField, dateField ];
	}

	prependTitleOption(options, label, isSelected) {
		options.unshift({
			label,
			value: '',
			isSelected
		});
		return options;
	}

	buildTradeOptions(tradesEnabled, selectedTrade) {
		const trades = this.store.trades;
		const hasNoSelected = !selectedTrade;
		let options = tradesEnabled.map((tradeId) => {
			const trade = trades.find((trade) => { 
				return trade.id === tradeId; 
			});
			return {
				label: trade.name,
				value: tradeId,
				isSelected: tradeId === selectedTrade
			};
		}).sort(sortByLabelAlpha);
		options = this.prependTitleOption(options, 'Any destination', hasNoSelected);
		return options;
	}

	buildDateOptions(datesEnabled, selectedDate) {
		const hasNoSelected = !selectedDate;
		let options = datesEnabled.map((dateString) => {
			const date = parseDate(dateString, 'MMyy', new Date());
			const label = formatDate(date, 'MMMM yyyy');
			const sortValue = getUnixTime(date);
			return {
				label,
				value: dateString,
				isSelected: selectedDate === dateString,
				sortValue
			};
		}).sort(sortBySortValue);
		options = this.prependTitleOption(options, 'Any date', hasNoSelected);
		return options;
	}

	buildPortOptions(portsEnabled, selectedPort) {
		const hasNoSelected = !selectedPort;
		let options = portsEnabled.map((portId) => {
			const port = this.store.ports.find((port) => {
				return port.id === portId;
			});
			const label = port.name;
			const value = portId;
			return {
				label,
				value,
				isSelected: portId === selectedPort
			};
		}).sort(sortByLabelAlpha);
		options = this.prependTitleOption(options, 'Any port', hasNoSelected);
		return options;
	}

	buildDurationOptions(durationsEnabled, selectedDuration) {
		const hasNoSelected = !selectedDuration;
		let options = durationsEnabled.map((durationId) => {
			const duration = durationLimits.find((durationLimit) => {
				return durationLimit.id === durationId;
			});
			return {
				label: duration.value,
				value: durationId,
				isSelected: durationId === selectedDuration,
				sortValue: duration.lowerLimit
			};
		}).sort(sortBySortValue);
		options = this.prependTitleOption(options, 'Any', hasNoSelected);
		return options;
	}

	buildUrl(state) {
		const { selectedTrade, selectedDate, selectedPort, selectedDuration, isPastGuest } = state;
		const hasParams = !!selectedTrade || !!selectedDate || !!selectedPort || !!selectedDuration || isPastGuest;
		const params = [];
		selectedTrade && params.push(`trade=${selectedTrade}`);
		selectedDate && params.push(`date=${selectedDate}`);
		selectedPort && params.push(`startPort=${selectedPort}`);
		selectedDuration && params.push(`duration=${durationToQueryParam(selectedDuration)}`);
		isPastGuest && params.push(`ppax=${isPastGuest}`);
		let cspath = 'results';
		if (process.env.REACT_APP_DEPLOY_TARGET !== 'prod') {			
			cspath = 'search';
		}
		const url = `${this.config.wwwHost}/cruise-search/${cspath}/`;
		return hasParams ? `${url}?${params.join('&')}` : url;
	}	
}